import React from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import SimpleFormat from '@16g/react-simple-format';

const rootNode = document.getElementById('global-toasts-stack-root');
const toastsStack = JSON.parse(get(rootNode, ['dataset', 'toastsStack']) || '[]');

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  theme: 'colored',
  icon: false,
});

const toastTypeByVariant = (variant) => {
  if (['alert', 'error', 'warning'].includes(variant)) return 'error';
  return 'success';
};

toastsStack.forEach(({ variant, text }) => {
  toast[toastTypeByVariant(variant)](() => <SimpleFormat text={text} />);
});
